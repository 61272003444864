<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">主題區</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                主題區
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <router-link :to="`/article/${firstArticle.id}`" class="card">
          <div class="position-relative">
            <img
              class="card-img-top banner-img"
              :src="firstArticle.banner"
              alt="Card image cap"
            />
            <div class="card-img-overlay">
              <span class="badge badge-warning badge-rounded-circle"
                ><i class="fa fa-file-text-o" aria-hidden="true"></i
              ></span>
            </div>
          </div>
          <div
            class="card-body border-top-5 px-3 rounded-bottom border-warning"
          >
            <h3 class="card-title text-warning mb-5">
              <router-link
                :to="`/article/${firstArticle.id}`"
                class="text-success text-capitalize d-block text-warning"
                >{{ firstArticle.title }}
              </router-link>
            </h3>
          </div>
        </router-link>
        <div class="row">
          <!-- 熱門文章 -->
          <div
            class="owl-carousel owl-theme wow fadeInUp"
            dir="ltr"
            id="hotArticle-slider"
          >
            <div class="card" v-for="item in hotArticle" :key="item.id">
              <router-link :to="`/article/${item.id}`" class="position-relative">
                <div class="position-relative">
                  <img
                    class="card-img-top adjust-height"
                    :src="item.banner"
                    alt="Card image"
                  />
                  <div class="card-img-overlay">
                    <span
                      :class="`badge-${item.themeClass}`"
                      class="badge badge-rounded-circle"
                      ><i class="fa fa-file-text-o" aria-hidden="true"></i
                    ></span>
                  </div>
                </div>
                <div
                  :class="`border-${item.themeClass}`"
                  class="card-body border-top-5 px-3 rounded-bottom"
                >
                  <h3 class="card-title">
                    <router-link
                      :to="`/article/${item.id}`"
                      :class="`text-${item.themeClass}`"
                      class="text-capitalize d-block text-truncate"
                    >
                      {{ item.title }}
                    </router-link>
                  </h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <section class="pt-9 py-md-7">
          <div class="container">
            <div class="section-title justify-content-center mb-6 wow fadeInUp">
              <span class="shape shape-left bg-info"></span>
              <h2 class="text-danger">熱門影音</h2>
              <span class="shape shape-right bg-info"></span>
            </div>
            <div class="row">
              <div class="owl-carousel owl-theme wow fadeInUp" id="video-slider">
                <div
                  class="card"
                  v-for="video in videos"
                  :key="video.id"
                >
                  <router-link :to="`/video/${video.id}`" class="position-relative">
                    <div class="">
                      <img
                        class="card-img-top adjust-height"
                        :src="video.banner"
                        alt="Card image"
                      />
                      <div class="card-img-overlay">
                        <span
                          :class="`badge-${video.themeClass}`"
                          class="badge badge-rounded-circle"
                          ><i class="fa fa fa-film" aria-hidden="true"></i
                        ></span>
                      </div>
                    </div>
                    <div
                      :class="`border-${video.themeClass}`"
                      class="card-body border-top-5 px-3 rounded-bottom"
                    >
                      <h3 class="card-title">
                        <router-link
                          :to="`/video/${video.id}`"
                          :class="`text-${video.themeClass}`"
                          class="text-capitalize d-block text-truncate"
                          >{{ video.title }}
                        </router-link>
                      </h3>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="pt-9 py-md-7">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-4 d-none d-lg-block col-sm-12 mb-4 mb-md-0 order-2"
              >
                <div class="row">
                  <div class="col-md-6 col-lg-12 mb-6">
                    <div class="position-relative">
                      <img
                        class="card-img-top"
                        src="assets/img/courses/courses-img2.png"
                        alt="Card image"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 mb-6">
                    <div class="position-relative">
                      <img
                        class="card-img-top"
                        src="assets/img/courses/courses-img1.png"
                        alt="Card image"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 mb-6">
                    <div class="position-relative">
                      <img
                        class="card-img-top"
                        src="assets/img/courses/courses-img3.png"
                        alt="Card image"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-12 mb-6">
                    <div class="position-relative">
                      <img
                        class="card-img-top"
                        src="assets/img/courses/courses-img4.png"
                        alt="Card image"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-sm-12">
                <div
                  class="section-title justify-content-center mb-6 wow fadeInUp"
                >
                  <span class="shape shape-left bg-info"></span>
                  <h2 class="text-danger">專家觀點</h2>
                  <span class="shape shape-right bg-info"></span>
                </div>
                <div class="row">
                  <div
                    class="col-md-6 col-xs-12"
                    v-for="article in column"
                    :key="article.id"
                  >
                    <router-link :to="`/article/${article.id}`" class="card">
                      <div class="position-relative">
                        <img
                          class="card-img-top adjust-height"
                          :src="article.banner"
                          alt="Card image"
                        />
                        <div class="card-img-overlay">
                          <span
                            :class="`badge-${article.themeClass}`"
                            class="badge badge-rounded-circle"
                            ><i class="fa fa-file-text-o" aria-hidden="true"></i
                          ></span>
                        </div>
                      </div>
                      <div
                        :class="`border-${article.themeClass}`"
                        class="card-body border-top-5 px-3 rounded-bottom"
                      >
                        <h3 class="card-title">
                          <router-link
                            :to="`/article/${article.id}`"
                            :class="`text-${article.themeClass}`"
                            class="text-capitalize d-block text-truncate"
                          >
                            {{ article.title }}
                          </router-link>
                        </h3>
                      </div>
                    </router-link>
                  </div>
                </div>

                <div
                  class="section-title justify-content-center my-6 wow fadeInUp"
                >
                  <span class="shape shape-left bg-info"></span>
                  <h2 class="text-danger">我為何要學理財</h2>
                  <span class="shape shape-right bg-info"></span>
                </div>
                <div class="row">
                  <div
                    class="col-md-6 col-xs-12"
                    v-for="article in management"
                    :key="article.id"
                  >
                    <router-link :to="`/article/${article.id}`" class="card">
                      <div class="position-relative">
                        <img
                          class="card-img-top adjust-height"
                          :src="article.banner"
                          alt="Card image"
                        />
                        <div class="card-img-overlay">
                          <span
                            :class="`badge-${article.themeClass}`"
                            class="badge badge-rounded-circle"
                            ><i class="fa fa-file-text-o" aria-hidden="true"></i
                          ></span>
                        </div>
                      </div>
                      <div
                        :class="`border-${article.themeClass}`"
                        class="card-body border-top-5 px-3 rounded-bottom"
                      >
                        <h3 class="card-title">
                          <router-link
                            :to="`/article/${article.id}`"
                            :class="`text-${article.themeClass}`"
                            class="text-capitalize d-block text-truncate"
                          >
                            {{ article.title }}
                          </router-link>
                        </h3>
                      </div>
                    </router-link>
                  </div>
                </div>

                <div
                  class="section-title justify-content-center my-6 wow fadeInUp"
                >
                  <span class="shape shape-left bg-info"></span>
                  <h2 class="text-danger">記帳教我的事</h2>
                  <span class="shape shape-right bg-info"></span>
                </div>
                <div class="row">
                  <div
                    class="col-md-6 col-xs-12"
                    v-for="article in trackExpenses"
                    :key="article.id"
                  >
                    <router-link :to="`/article/${article.id}`" class="card">
                      <div class="position-relative">
                        <img
                          class="card-img-top adjust-height"
                          :src="article.banner"
                          alt="Card image"
                        />
                        <div class="card-img-overlay">
                          <span
                            :class="`badge-${article.themeClass}`"
                            class="badge badge-rounded-circle"
                            ><i class="fa fa-file-text-o" aria-hidden="true"></i
                          ></span>
                        </div>
                      </div>
                      <div
                        :class="`border-${article.themeClass}`"
                        class="card-body border-top-5 px-3 rounded-bottom"
                      >
                        <h3 class="card-title">
                          <router-link
                            :to="`/article/${article.id}`"
                            :class="`text-${article.themeClass}`"
                            class="text-capitalize d-block text-truncate"
                          >
                            {{ article.title }}
                          </router-link>
                        </h3>
                      </div>
                    </router-link>
                  </div>
                </div>

                <div
                  class="section-title justify-content-center my-6 wow fadeInUp"
                >
                  <span class="shape shape-left bg-info"></span>
                  <h2 class="text-danger">零用錢三堂課</h2>
                  <span class="shape shape-right bg-info"></span>
                </div>
                <div class="row">
                  <div
                    class="col-md-6 col-xs-12"
                    v-for="article in pocketMoney"
                    :key="article.id"
                  >
                    <router-link :to="`/article/${article.id}`" class="card">
                      <div class="position-relative">
                        <img
                          class="card-img-top adjust-height"
                          :src="article.banner"
                          alt="Card image"
                        />
                        <div class="card-img-overlay">
                          <span
                            :class="`badge-${article.themeClass}`"
                            class="badge badge-rounded-circle"
                            ><i class="fa fa-file-text-o" aria-hidden="true"></i
                          ></span>
                        </div>
                      </div>
                      <div
                        :class="`border-${article.themeClass}`"
                        class="card-body border-top-5 px-3 rounded-bottom"
                      >
                        <h3 class="card-title">
                          <router-link
                            :to="`/article/${article.id}`"
                            :class="`text-${article.themeClass}`"
                            class="text-capitalize d-block text-truncate"
                          >
                            {{ article.title }}
                          </router-link>
                        </h3>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
/* global $ */
import tokenGenerator from '@/tokenGenerator.js'

export default {
  name: 'topic',
  data () {
    return {
      hooperSettings: {
        itemsToShow: 4,
        infiniteScroll: true,
        settings: {
          margin: 30
        }
      },
      videos: [],
      hotArticle: [],
      column: [],
      management: [],
      trackExpenses: [],
      pocketMoney: [],
      responseData: [],
      firstArticle: {}
    }
  },
  created () {
    const vm = this
    vm.getVideo()
    vm.getColumn()
    vm.getManagement()
    vm.getTrackExpenses()
    vm.getPocketMoney()
  },
  mounted () {
    require('@/assets/js/base.js')
    this.getHotArticle().then(() => {})
    this.getVideo().then(() => {})
  },
  methods: {
    // 取得最新文章
    getHotArticle () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

        vm.axios
          .post(api, {
            token: tokenGenerator(),
            type: ['1'],
            categoryId: ['7', '8', '9', '10', '11', '12', '13', '14', '15'],
            pageSize: 5,
            page: 1
          })
          .then((res) => {
            vm.hotArticle = res.data.content
            vm.firstArticle = vm.hotArticle[0]
            vm.hotArticle.shift()
            vm.hotArticle.forEach((item, index) => {
              const i = index + 1
              if (i % 4 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 4 === 2) {
                return (item.themeClass = 'success')
              } else if (i % 4 === 3) {
                return (item.themeClass = 'danger')
              } else {
                return (item.themeClass = 'info')
              }
            })
            vm.$nextTick(() => {
              $('#hotArticle-slider').owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                nav: true,
                navText: [
                  '<i class="fa fa-arrow-left"></i>',
                  '<i class="fa fa fa-arrow-right"></i>'
                ],
                responsive: {
                  0: {
                    items: 1
                  },
                  600: {
                    items: 1
                  },
                  1000: {
                    items: 4
                  }
                }
              })
            })
            resolve()
          })
      })
    },
    // 專家觀點
    getColumn () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.axios
        .post(api, {
          token: tokenGenerator(),
          type: ['1'],
          categoryId: ['14'],
          pageSize: 2,
          page: 1
        })
        .then((res) => {
          vm.column = res.data.content
          vm.column.forEach((item, index) => {
            const i = index + 1
            if (i % 2 === 1) {
              return (item.themeClass = 'warning')
            } else {
              return (item.themeClass = 'success')
            }
          })
        })
    },
    // 我為何要學理財
    getManagement () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.axios
        .post(api, {
          token: tokenGenerator(),
          type: ['1'],
          categoryId: ['7'],
          pageSize: 2,
          page: 1
        })
        .then((res) => {
          vm.management = res.data.content
          vm.management.forEach((item, index) => {
            const i = index + 1
            if (i % 2 === 1) {
              return (item.themeClass = 'warning')
            } else {
              return (item.themeClass = 'success')
            }
          })
        })
    },
    // 記帳教我的事
    getTrackExpenses () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.axios
        .post(api, {
          token: tokenGenerator(),
          type: ['1'],
          categoryId: ['8'],
          pageSize: 2,
          page: 1
        })
        .then((res) => {
          vm.trackExpenses = res.data.content
          vm.trackExpenses.forEach((item, index) => {
            const i = index + 1
            if (i % 2 === 1) {
              return (item.themeClass = 'warning')
            } else {
              return (item.themeClass = 'success')
            }
          })
        })
    },
    // 零用錢三堂課
    getPocketMoney () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

      vm.axios
        .post(api, {
          token: tokenGenerator(),
          type: ['1'],
          categoryId: ['9'],
          pageSize: 2,
          page: 1
        })
        .then((res) => {
          vm.pocketMoney = res.data.content
          vm.pocketMoney.forEach((item, index) => {
            const i = index + 1
            if (i % 2 === 1) {
              return (item.themeClass = 'warning')
            } else {
              return (item.themeClass = 'success')
            }
          })
        })
    },
    // 取得最新影音
    getVideo () {
      return new Promise((resolve, reject) => {
        const vm = this
        const api = `${process.env.VUE_APP_APIPATH}/iPlay/article/list`

        vm.axios
          .post(api, {
            token: tokenGenerator(),
            type: ['2', '3'],
            // type: ['1'],
            categoryId: ['7', '8', '9', '10', '11', '12', '13', '14', '15'],
            pageSize: 3,
            page: 1
          })
          .then((res) => {
            vm.videos = res.data.content
            vm.videos.forEach((item, index) => {
              const i = index + 1
              if (i % 3 === 1) {
                return (item.themeClass = 'warning')
              } else if (i % 3 === 2) {
                return (item.themeClass = 'success')
              } else {
                return (item.themeClass = 'danger')
              }
            })
            vm.$nextTick(() => {
              $('#video-slider').owlCarousel({
                loop: true,
                margin: 30,
                dots: false,
                nav: true,
                navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa fa-arrow-right"></i>'],
                responsive: {
                  0: {
                    items: 1
                  },
                  600: {
                    items: 1
                  },
                  1000: {
                    items: 3
                  }
                }
              })
            })
            resolve()
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/kidz.css";
.adjust-height {
  height: 225px;
  object-fit: cover;
}
.banner-img {
  height: 800px;
  object-fit: cover;
}
@media (max-width: 576px) {
  .banner-img {
    height: 240px;
  }
}
</style>
