import { render, staticRenderFns } from "./Topic.vue?vue&type=template&id=0c75c366&scoped=true&"
import script from "./Topic.vue?vue&type=script&lang=js&"
export * from "./Topic.vue?vue&type=script&lang=js&"
import style0 from "./Topic.vue?vue&type=style&index=0&id=0c75c366&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c75c366",
  null
  
)

export default component.exports